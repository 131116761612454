<template>
  <div class="card card-custom mt-4">
    <div class="card-body">
      <div
        class="d-flex justify-content-center align-items-center h-75"
        v-if="carregando"
      >
        <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
          ><v-progress-circular
            indeterminate
            size="24"
            color="#ef720f"
            width="2"
            rotate="180"
          ></v-progress-circular
        ></v-progress-circular>
      </div>
      <div class="w-100 d-flex justify-content-end mb-2" v-else>
        <b-button
          v-if="isAdmin || hasPermission('gerenciar-campanha')"
          variant="success"
          class="b-button-custom ml-auto"
          @click="clickBotaoCadastro"
        >
          Nova Agenda
        </b-button>
      </div>
      <div
        class="d-flex flex-column justify-content-between align-items-between h-100"
      >
        <b-table
          id="tabelaAgenda"
          small
          :current-page="paginaAtual"
          :fields="cabecalho"
          :items="linhas"
          :per-page="selectQuant.value"
          @row-clicked="detalhes"
          responsive="sm"
          hover
          sort-by="nome"
        >
          <template #cell(status)="data">
            <span
              class="label label-success label-inline mr-2"
              v-if="data.value === 'A'"
              >Ativo</span
            >
            <span class="label label-danger label-inline mr-2" v-else
              >Inativo</span
            >
          </template>

          <template #cell(actions)="row">
            <b-dropdown
              no-caret
              variant="outline-secondary"
              toggle-class="btn-icon"
              dropleft
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v p-0"></i>
              </template>

              <b-dropdown-item href="#" @click="detalhes(row.item)"
                >Detalhes</b-dropdown-item
              >

              <b-dropdown-item
                href="#"
                @click="editarAgenda(row.item)"
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                >Editar</b-dropdown-item
              >

              <b-dropdown-item
                href="#"
                @click="duplicarAgenda(row.item)"
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                >Duplicar</b-dropdown-item
              >

              <b-dropdown-item href="#" @click="excluir(row.item.id, row.index)"
                >Excluir</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex w-100">
          <b-row class="w-100">
            <b-col md="4"
              ><b-form-select
                v-model="selectQuant.value"
                :options="selectQuant.options"
              ></b-form-select
            ></b-col>
            <b-col md="8"
              ><b-pagination
                v-model="paginaAtual"
                :total-rows="linhas.length"
                :per-page="porPagina"
                aria-controls="tabelaAgenda"
                align="fill"
              ></b-pagination
            ></b-col>
          </b-row>
        </div>
      </div>

      <b-modal
        id="modal-detalhes-agenda"
        body-class="p-0"
        size="xl"
        hide-footer
        hide-header
      >
        <modal-detalhes-agenda
          @botao-voltar="$bvModal.hide('modal-detalhes-agenda')"
          :agenda="itemSelecionado"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import DetalheAgenda from "./DetalheAgenda.vue";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    "modal-detalhes-agenda": DetalheAgenda
  },

  data() {
    return {
      selectQuant: {
        value: 7,
        options: [
          { value: 7, text: "7" },
          { value: 10, text: "10" },
          { value: 15, text: "15" },
          { value: 20, text: "20" },
          { value: 25, text: "25" },
          { value: 30, text: "30" }
        ]
      },
      carregando: true,
      cabecalho: [
        {
          key: "nome",
          label: "Nome",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "horario_inicial",
          label: "Hora Inicial",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "horario_final",
          label: "Hora Final",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "intervalo_horario_inicial",
          label: "Intervalo Horário Inicial",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "intervalo_horario_final",
          label: "Intervalo Horário Final",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "quantidade_atendimentos",
          label: "Qtd. Atendimentos",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "actions",
          label: "Ações",
          sortable: true,
          class: "align-middle text-center"
        }
      ],
      linhas: [],
      paginaAtual: 1,
      itemSelecionado: {}
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    porPagina() {
      return this.selectQuant.value;
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agende" },
      {
        title: "Agenda",
        route: "/painel/agende/agenda"
      }
    ]);
    this.listarAgendas();
  },

  methods: {
    clickBotaoCadastro() {
      this.$router.push({
        path: "/painel/automacao/cadastro-agenda"
      });
    },
    listarAgendas() {
      ApiService.get("agenda").then((response) => {
        this.linhas = response.data.data;
        this.carregando = false;
      });
    },
    editarAgenda(agenda) {
      this.$router.push({
        name: "automacao.agenda.cadastro",
        params: {
          agendaObj: agenda
        }
      });
    },
    duplicarAgenda(agenda) {
      Swal.fire({
        title: "Deseja realmente duplicar esta agenda?",
        showCancelButton: true,
        confirmButtonText: "Duplicar",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-info"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.get(`agenda/duplicar/${agenda.id}`).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Agenda duplicada com sucesso!",
              icon: "success",
              heightAuto: false
            });

            this.listarAgendas();
          });
        }
      });
    },

    detalhes(item) {
      this.itemSelecionado = item;

      this.$bvModal.show("modal-detalhes-agenda");
    },

    excluir(id, index) {
      const self = this;

      Swal.fire({
        title: "Deseja realmente excluir esta agenda?",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.delete(`agenda/${id}`).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Agenda excluída com sucesso!",
              icon: "success",
              heightAuto: false
            });

            self.linhas.splice(index, 1);
          });
        }
      });
    }
  }
};
</script>
