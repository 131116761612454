<template>
  <div style="padding: 5px 20px; height: auto">
    <Toast position="top-right" />
    <div class="modal-detalhes-cabecacho m-3">
      <div class="back-arrow-squared" @click="$emit('botao-voltar')">
        <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
      </div>
      <div class="d-flex">
        <b-button
          variant="secondary"
          class="text-uppercase"
          @click="editarAgenda()"
          v-if="isAdmin || hasPermission('gerenciar-campanha')"
          >Editar</b-button
        >
      </div>
    </div>
    <hr style="margin: 2px -20px 10px -20px" />
    <div class="container">
      <div
        class="align-items-center mb-10 mt-5 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-2 col-xl-1">
            <section style="display: flex; align-items: center">
              <div :class="{ 'status-on': ativo, 'status-off': !ativo }"></div>
            </section>
          </div>
          <div class="col-12 col-sm-6 col-xl-9 align-items-center">
            <span class="modal-detalhes-corpo-secao-informacao">
              <strong>{{ agenda.nome }}</strong></span
            >
          </div>
          <div class="col-12 col-sm-4 col-xl-2 d-flex flex-row-reverse">
            <b-button
              v-b-toggle="'cardCollapse'"
              style="padding: 0; border: none"
            >
              <a
                class="btn btn-icon btn-sm btn-light-primary card-button-collapse"
                style="width: 100px"
                @click="isDetalhesCollapsed = !isDetalhesCollapsed"
                >DETALHES
                <i
                  v-if="isDetalhesCollapsed"
                  class="ki ki-arrow-down icon-nm ml-2"
                  style="color: inherit"
                ></i>
                <i
                  v-if="!isDetalhesCollapsed"
                  class="ki ki-arrow-up icon-nm ml-2"
                  style="color: inherit"
                ></i>
              </a>
            </b-button>
          </div>
        </div>
        <b-collapse
          id="cardCollapse"
          visible
          style="border-top: 1px solid #eee; padding: 16px"
        >
          <div class="row">
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >ID da agenda</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.id
              }}</span>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao d-block"
                >Data de criação</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                obterDataFormatada(agenda.data_cadastro)
              }}</span>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Ultima atualização</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">
                {{ obterDataFormatada(agenda.data_atualizacao) }}</span
              >
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Horário inicial</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.horario_inicial
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Horário final</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.horario_final
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Intervalo horário inicial</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.intervalo_horario_inicial
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Intervalo horário final</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.intervalo_horario_final
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Qtd. de atendimentos</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.quantidade_atendimentos
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Tempo de atendimento</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.tempo_atendimento
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Intervalo entre atendimentos</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.intervalo_atendimento
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Tipo de atendimento</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">
                {{
                  agenda.tipo_atendimento === "HM"
                    ? "Horário marcado"
                    : "Ordem de chegada"
                }}
              </span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Data de início</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                obterDataFormatada(agenda.data_inicio) || "-"
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-3 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Data de fim</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                obterDataFormatada(agenda.data_fim) || "-"
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-6 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Dias da semana</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                agenda.dias_semana.join(", ")
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Tipo</span>
              <span class="modal-detalhes-corpo-secao-informacao">
                <span class="limita-caracteres">
                  {{ agenda.nomeTipo }}
                </span>
              </span>
            </div>

            <div class="col-12 col-sm-6 col-xl-8 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Modelo</span>
              <span class="modal-detalhes-corpo-secao-informacao">
                <span class="limita-caracteres">
                  {{ agenda.nomeModelo }}
                </span>
              </span>
            </div>
          </div>
        </b-collapse>
      </div>

      <div
        v-if="agenda.filtros.length > 0"
        class="align-items-center mb-10 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden d-flex justify-content-between"
          v-b-toggle="'cardCollapseFilter'"
          @click="isFilterCollapsed = !isFilterCollapsed"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-6 col-xl-9 align-items-center">
            <span class="modal-detalhes-corpo-secao-informacao">
              <strong>Filtros da Agenda</strong></span
            >
          </div>
          <div class="col-12 col-sm-4 col-xl-3 d-flex flex-row-reverse">
            <a>
              <i
                v-if="isFilterCollapsed"
                class="ki ki-arrow-down icon-nm ml-2"
                style="color: black"
              ></i>
              <i
                v-else
                class="ki ki-arrow-up icon-nm ml-2"
                style="color: black"
              ></i>
            </a>
          </div>
        </div>
        <b-collapse id="cardCollapseFilter" style="border-top: 1px solid #eee">
          <nested-draggable
            :group="0"
            :camposPossiveisDoFiltro="camposPossiveisDoFiltro"
            :opreadoresFiltro="opreadoresFiltro"
            :listaDeFiltros="agenda.filtros"
            :isDraggable="false"
            :sistemaLicenciadaId="agenda.licenciada"
          >
          </nested-draggable>
        </b-collapse>
      </div>
      <div
        v-if="agenda.filtros.length > 0"
        class="texto-literal p-4 border rounded mb-4"
        v-html="tratamentoFiltro(agenda.filtros)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import nestedDraggable from "../../automacao/campanha/cadastro-componentes/NestedDraggable.vue";
import Toast from "primevue/toast";
import moment from "moment";
import API_LINKS from "../../automacao/api.links";

export default {
  components: {
    Toast,
    nestedDraggable
  },

  props: ["agenda"],

  data() {
    return {
      isDetalhesCollapsed: false,
      isFilterCollapsed: true,
      opreadoresFiltro: [],
      camposPossiveisDoFiltro: []
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    ativo() {
      return this.agenda.status === "A";
    }
  },

  mounted() {
    ApiService.get(API_LINKS.campanha + "/operador").then((res) => {
      this.opreadoresFiltro = res.data.operadores.map((v) => {
        return {
          ope_titulo: v.ope_titulo,
          ope_sinal: v.ope_sinal,
          operador_id: v.operador_id
        };
      });
    });

    ApiService.get(API_LINKS.campanhaFiltro + "/" + this.agenda.modelo).then(
      (res) => {
        this.camposPossiveisDoFiltro = res.data.filtros.map((filtro) => {
          return {
            fil_campo: filtro.fil_campo,
            fil_titulo: filtro.fil_titulo,
            filtro_id: filtro.filtro_id,
            ope_titulo: filtro.ope_titulo,
            fil_tipo_dado: filtro.fil_tipo_dado,
            fil_tipo: filtro.fil_tipo,
            fil_mascara: filtro.fil_mascara
          };
        });
      }
    );
  },

  methods: {
    editarAgenda() {
      this.$router.push({
        name: "automacao.agenda.cadastro",
        params: {
          agendaObj: this.agenda
        }
      });
    },
    obterDataFormatada(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss")
            .locale("pt-br")
            .format("DD [de] MMMM[,] YYYY")
        : "-";
    },
    obterDataFormatadaHora(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss").locale("pt-br").format("hh:mm")
        : "-";
    },
    tratamentoFiltro(filtros) {
      if (filtros.length == 0) return;
      var texto = "";
      filtros.forEach((filtro) => {
        if (filtro.listaDeFiltros.length == 0) {
          texto += `<strong style="color: #bb3e03">${filtro.fil_titulo
            ?.split("[")[0]
            .replace(";", "")}</strong>  ${
            filtro.ope_titulo?.split("[")[0]
          } <strong style="color: #bb3e03; font-weight: 600">${this.tratamentoComponenteValor(
            filtro
          )}</strong>${
            filtro.mfi_parenteses_fim ?? ""
          }<strong style="color: #264653"; font-weight: 600> ${
            filtro.mfi_e_ou == "AND" ? "E" : filtro.mfi_e_ou == "OR" ? "OU" : ""
          } </strong>`;
        } else {
          texto += `(${this.tratamentoFiltro(filtro.listaDeFiltros)}`;
        }
      });
      return texto;
    },
    tratamentoComponenteValor(filtro) {
      if (filtro.ope_sinal == "IS_NULL") {
        return "";
      }
      if (
        (this.checkStringEmptyNull(filtro.mfi_valor) &&
          this.checkStringEmptyNull(filtro.mfi_valor_2)) ||
        (this.checkStringEmptyNull(filtro.mfi_valor) && // Para contornar erro de mfi_valor_2 vindo preenchido mesmo com função de contexto
          !this.checkStringEmptyNull(filtro.mfi_valor_2))
      ) {
        if (filtro) {
          return filtro.con_titulo?.split("[")[0].replace(";", "");
        }
      }
      if (
        !this.checkStringEmptyNull(filtro.mfi_valor) &&
        !this.checkStringEmptyNull(filtro.mfi_valor_2)
      ) {
        return `${filtro.mfi_valor} - ${filtro.mfi_valor_2}`;
      }
      return filtro.mfi_valor;
    },
    checkStringEmptyNull(valor) {
      return valor == null || valor === "";
    }
  }
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600&display=swap");

.texto-literal {
  background-color: #fcfafa;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 1.5px;
  font-size: 1rem;
}

.filtros-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
}
.modal-detalhes-cabecacho {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.modal-detalhes-cabecacho-botao-voltar {
  width: 35px;
  height: 35px;
  margin-top: 2px;
  border: 2.5px solid #808080;
  color: #808080;
  border-radius: 50%;
}

.modal-detalhes-corpo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detalhes-collapse {
  outline: 1px solid #eee;
  padding: 0;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 0.42rem;
}

.modal-detalhes-corpo-secao {
  display: flex;
  flex-direction: column;
  /* height: 75px; */
  width: 250px;
  margin-top: 1rem;
}

/* Texto menor */
.modal-detalhes-corpo-secao-descricao {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
}

/* Texto maior */
.modal-detalhes-corpo-secao-informacao {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.33rem;
}
img {
  max-width: 100% !important;
}

.modal-detalhes-corpo-texto-campanha {
  width: 70%;
}

.modal-detalhes-corpo-status {
  display: block;
  margin-left: 20px;
}

.modal-detalhes-mensagem {
  width: 100%;
}

.camp-body-right-textarea {
  border: 1px solid #e4e6ef;
  border-top: none;
  background-color: #fff;
  padding: 20px;
  border-radius: 0px 7px 7px 7px;
  box-shadow: 0px 0px 5px rgb(245, 245, 245);
  min-height: 150px;
  padding-bottom: 20px;
  /* margin-bottom: 15px; */
}

.limita-caracteres {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.modal-detalhes-mensagem-textarea {
  background-color: #fff;
  border: 1px solid #a0a0a0;
  border-color: #e4e6ef #e4e6ef #e4e6ef;
  height: 200px;
  border-radius: 0px 0px 25px 25px;
  /* border: 1px solid #acacac; */
  padding: 20px;
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  font-size: 16px;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.block {
  display: block;
}

.rounded-top-rigth {
  border-radius: 10px 0px 0px 0px;
}

.nav-item > a,
.nav-item > a:hover,
.nav-link.active {
  /* border: 1px solid #a0a0a0; */
  border-bottom: none;
}

.status-on {
  background-color: rgb(97, 191, 3);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
.status-off {
  background-color: rgb(214, 21, 21);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
#blockly1 {
  display: block;
  width: 100%;
  height: 390px;
}

.injectionDiv > svg {
  border-radius: 10px !important;
}

.blocklyFlyoutBackground {
  display: none !important;
}

.caixa-divisao {
  background-color: #ebedf3;
  padding: 15px;
  padding-top: 25px;
  border-radius: 25px;
  margin-top: 30px;
}

.camp-botao-voltar {
  color: #808080;
  transition: all 0.1s linear;
  cursor: pointer;
}

.camp-botao-voltar:hover {
  transform: scale(1.1);
}

.trash-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-trash:before {
  color: red;
}
.botao-filtro {
  width: 100%;
  padding: 2px 6px;
  border-radius: 5px;
  z-index: 10;
}
.camp-body {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.camp-body-left {
  width: 50%;
}

.camp-body-right {
  min-width: 50%;
  padding: 0px 30px;
}

.btn-executar-campanha {
  transition: 0s all;
  text-transform: uppercase;
  font-weight: bold;
  /* width: 200px; */
  padding: 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #007bff;
  /* background: #ffa800; */
  line-height: 0;
  color: #fff;
}
.executed {
  pointer-events: none;
  background: #454545 !important;
  border: none;
  cursor: not-allowed !important;
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
